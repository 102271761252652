import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { amountToString } from '@orus.eu/amount'
import { paymentRecurrenceLabel, type AggregatedQuote } from '@orus.eu/dimensions'
import { Text, colorTokens, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'

export const SubscriptionPriceRecurrence = memo<{
  quote: AggregatedQuote
}>(function SubscriptionPriceRecurrence({ quote }) {
  const { paymentRecurrence } = quote
  const amount = paymentRecurrence === 'yearly' ? quote.yearlyTotalPremium : quote.subsequentMonthsTotalPremium
  const paymentRecurrenceLabelString = paymentRecurrenceLabel.masculine.normal[paymentRecurrence]
  const paymentRecurrenceLabelStringCapitalized =
    paymentRecurrenceLabelString.charAt(0).toUpperCase() + paymentRecurrenceLabelString.slice(1)

  return (
    <FlexRow>
      <Text variant="body2">{amountToString(amount, { addCurrency: true })}</Text>
      <Badge>{paymentRecurrenceLabelStringCapitalized}</Badge>
    </FlexRow>
  )
})

const FlexRow = styled.div`
  display: flex;
  gap: ${spacing[50]};
`

const Badge = memo<{ children: string }>(function Badge({ children }) {
  return (
    <Text
      css={css`
        background-color: ${colorTokens['color-bg-neutral']};
        color: ${colorTokens['color-text-base-primary']};
        border-radius: ${spacing[20]};
        padding: ${spacing[10]} ${spacing[20]};
      `}
      variant="captionMedium"
    >
      {children}
    </Text>
  )
})
